import React, { useState, useEffect } from "react";
import useTranslations from "hooks/useTranslations";
import styled from "@emotion/styled";
import { mq } from "utils/helper";
import { rhythm } from "utils/typography";
import { toLower } from "ramda";
import DayPickerInput from "react-day-picker/DayPickerInput";

const FILTER_ATTRIBUTES = {
  poss_perps: {
    inc_attr: "poss_perps",
    label: "Possible Perpetrators",
    type: "checkbox",
    values: [
      { value: "rsf", label: "RSF" },
      { value: "saf", label: "SAF" },
      { value: "crf", label: "CRF" },
      { value: "police", label: "Riot police" },
    ],
  },
  date_from: {
    inc_attr: "date_of_incident",
    label: "After date",
    type: "date",
    operator: ">",
  },
  date_to: {
    inc_attr: "date_of_incident",
    label: "Before date",
    type: "date",
    operator: "<",
  },
};
const SortOption = styled.span({
  paddingRight: "0.6rem",
  paddingLeft: "0.6rem",
  cursor: "pointer",
  [mq[1]]: {
    padding: 0,
  },
});

function FilterCheckbox({ attr, value, checked, onChange }) {
  const tr = useTranslations();
  const id = value.value;
  return (
    <SortOption
      css={{
        [mq[1]]: {
          display: "block",
        },
      }}
    >
      <label htmlFor={id} style={{ marginRight: 5 }}>
        {tr(value.label)}
      </label>
      <input
        id={id}
        type="checkbox"
        name={value.value}
        value={value.value}
        onChange={(e) => onChange(attr, value.value, e.target.checked)}
        checked={checked}
      />
    </SortOption>
  );
}

function FilterBar({ isLoading, incidents, onResultChange, attributes }) {
  const tr = useTranslations();
  const [resultCount, setResultCount] = useState(incidents.length);

  const getInitialFilterState = () => {
    let filters = {};
    attributes.forEach((attr) => {
      let fa = FILTER_ATTRIBUTES[attr];
      if (fa.type === "checkbox") {
        // Initially check all values
        if (!filters[attr]) {
          filters[attr] = fa.values.map((fv) => fv.value);
        }
      }
    });
    return filters;
  };

  const [currentFilter, setCurrentFilter] = useState(getInitialFilterState());

  const handleFilterChange = (attr, value, checked) => {
    let type = FILTER_ATTRIBUTES[attr].type;
    if (type === "checkbox") {
      // Toggle in list
      if (currentFilter[attr] === null) {
        currentFilter[attr] = [];
      }
      let index = currentFilter[attr].indexOf(value);
      let inList = index > -1;
      if (checked && !inList) {
        currentFilter[attr].push(value);
      } else if (!checked && inList) {
        currentFilter[attr].splice(index, 1);
      }
    } else if (type === "date") {
      currentFilter[attr] = value;
    }
    setCurrentFilter({ ...currentFilter });
  };

  const getFilteredIncidents = () => {
    const filteredIncidents = incidents.filter((inc) => {
      let filterPasses = true;
      attributes.forEach((attr) => {
        let attrObj = FILTER_ATTRIBUTES[attr];
        let filterValue = currentFilter[attr];
        let attrPasses = true;
        if (filterValue != null) {
          let incValue = inc[attrObj.inc_attr];
          let attrType = attrObj.type;
          if (attrType === "checkbox") {
            // Attribute passes if any checked values are in incident attribute
            let any_match = false;
            if (incValue != null) {
              filterValue.forEach((fv) => {
                if (toLower(incValue).includes(toLower(fv))) {
                  any_match = true;
                }
              });
            }
            if (!any_match) attrPasses = false;
          } else if (attrType === "date") {
            let incDate = new Date(incValue);
            if (attrObj.operator === ">") {
              attrPasses = incDate > filterValue;
            } else if (attrObj.operator === "<") {
              attrPasses = incDate < filterValue;
            }
          }
        }
        if (!attrPasses) filterPasses = false;
      });
      return filterPasses;
    });
    return filteredIncidents;
  };

  useEffect(() => {
    let incdts = getFilteredIncidents();
    const n = incdts.length;
    onResultChange(incdts);
    setResultCount(n);
  }, [currentFilter]);

  const renderAttributeInputs = () => {
    return attributes.map((attr) => {
      let attrObject = FILTER_ATTRIBUTES[attr];
      if (attrObject != null) {
        if (attrObject.type === "checkbox") {
          let checkboxes = attrObject.values.map((val) => {
            let checked = currentFilter[attr]?.indexOf(val.value) > -1;
            return (
              <FilterCheckbox
                key={val.value}
                attr={attr}
                value={val}
                onChange={handleFilterChange}
                checked={checked}
              />
            );
          });
          return (
            <div className="FilterAttribute">
              <span className="FilterAttributeLabel">
                {tr(attrObject.label)}
              </span>
              {checkboxes}
            </div>
          );
        } else if (attrObject.type === "date") {
          return (
            <div className="FilterAttribute">
              <span className="FilterAttributeLabel">
                {tr(attrObject.label)}
              </span>
              <DayPickerInput
                classNames={{ container: "ShortDayPicker" }}
                clickUnselectsDay={true}
                onDayChange={(day) => handleFilterChange(attr, day)}
              />
            </div>
          );
        }
      }
    });
  };

  return (
    <div
      css={{
        marginBottom: rhythm(1.5),
        width: "70%",
        [mq[1]]: {
          margin: rhythm(1),
        },
      }}
    >
      {isLoading ? (
        <div>{tr("Loading")} ...</div>
      ) : (
        <div
          css={{
            display: "flex",
            [mq[1]]: {
              display: "block",
            },
          }}
        >
          <div
            css={{
              [mq[1]]: {
                margin: 0,
                display: "block",
              },
            }}
          >
            <h4 css={{ marginTop: 0, color: "gray" }}>{tr("Filters")}</h4>

            {renderAttributeInputs()}
            <div className="ShowingLine">Showing {resultCount} result(s)</div>
          </div>
        </div>
      )}
    </div>
  );
}

FilterBar.propTypes = {};

export default FilterBar;
