import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { rhythm } from "utils/typography";
import Seo from "components/seo";
import Pagination from "rc-pagination";
import LocalizedLink from "components/localizedLink";
import IncidentList from "components/datasets/incident-list";
import FilterBar from "components/datasets/filter-bar";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import Layout from "components/layout";
import PostHeader from "components/PostHeader";
import SudanMap from "components/database/map";
import PropTypes from "prop-types";
import { mq } from "utils/helper";
import useIncidents from "hooks/useIncidents";
import { FaAngleLeft, FaCircle } from "react-icons/fa";
import { slice } from "ramda";
import useTranslations from "hooks/useTranslations";
import "style/pagination.css";
import "style/filter-bar.css";

const PER_PAGE = 20;

const Container = styled.div({
  display: "block",
  maxWidth: "1200px",
  margin: "auto",
  [mq[0]]: {
    display: "block",
  },
});

function GoBack() {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <LocalizedLink
        to={"datasets/coup-files"}
        css={{
          position: "relative",
          marginLeft: rhythm(0.5),
          [mq[1]]: {
            marginLeft: rhythm(1),
          },
        }}
      >
        <span
          css={{
            paddingRight: rhythm(0.4),
            position: "absolute",
            top: rhythm(-0.1),
            left: rhythm(-0.8),
          }}
        >
          <FaAngleLeft />
        </span>
        <span
          css={{
            fontWeight: "bold",
          }}
        >
          {tr("About this Database")}
        </span>
      </LocalizedLink>
      <h4
        css={{
          marginTop: rhythm(1),
          [mq[1]]: {
            marginLeft: rhythm(0.5),
          },
        }}
      >
        <FaCircle
          css={{
            marginLeft: isRTL ? "0.6rem" : 0,
            marginRight: isRTL ? 0 : "0.6rem",
            color: colors.primary,
            fontSize: ".8em",
          }}
        />
        {tr("Incidents Data")}
      </h4>
    </div>
  );
}

function ChemicalCollection({ data: collection, location }) {
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  const { incidents } = useIncidents("coupfiles");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredIncidents, setFilteredIncidents] = useState(null);

  const tr = useTranslations();
  // const [windowWidthSize] = useWindowSize();
  const [hoveredUnit, setHoveredUnit] = useState("IIIII");

  // const [isMobile, setIsMobile] = useState(false);

  const showIncidents =
    filteredIncidents != null ? filteredIncidents : incidents;

  // useEffect(() => {
  //   windowWidthSize < 992 ? setIsMobile(true) : setIsMobile(false);
  // }, [windowWidthSize]);

  return (
    <Layout>
      <PostHeader
        post={collection.mdx}
        location={location}
        featuredImage={false}
      />
      <Seo metadata={{ title: tr("Coup Files") }} />
      <div
        css={{
          backgroundColor: colors.bg,
          paddingTop: rhythm(1.0),
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <div
          css={{
            maxWidth: "1200px",
            margin: "auto",
          }}
        >
          <GoBack />

          <FilterBar
            incidents={incidents}
            attributes={["poss_perps", "date_from", "date_to"]}
            onResultChange={(res) => setFilteredIncidents(res)}
          />

          <div
            css={{
              margin: "0 auto",
              marginTop: rhythm(1),
              marginBottom: rhythm(2),
              [mq[1]]: {
                margin: rhythm(1),
              },
              top: "12vh",
              height: "65vh",
              boxSizing: "border-box",
            }}
          >
            <SudanMap
              items={showIncidents}
              type="incidents"
              hoveredItemID={hoveredUnit}
              lat={15.608584}
              lng={32.5421577}
              zoom={6}
              fullwidth
            />
          </div>

          <Container>
            <div>
              {slice(
                PER_PAGE * (currentPage - 1),
                PER_PAGE * (currentPage - 1) + PER_PAGE,
                showIncidents
              ).map((incident) => (
                <IncidentList
                  incident={incident}
                  key={incident.incident_id}
                  setHoveredUnit={setHoveredUnit}
                />
              ))}
            </div>
          </Container>

          <Pagination
            pageSize={PER_PAGE}
            current={currentPage}
            total={showIncidents.length}
            onChange={(current) => setCurrentPage(current)}
          />
        </div>
      </div>
    </Layout>
  );
}

ChemicalCollection.propTypes = {
  data: PropTypes.object,
};

export default ChemicalCollection;

export const pageQuery = graphql`
  query CollectionPage {
    mdx: strapiDataset {
      id
      title
      date
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      desc
      dataset
    }
  }
`;
